<template>
  <div class="hole-wrapper">
    <div id="app" class="ignore">
      <!-- 顶部导航 -->
      <div class="titlebar top-nav-ignore" v-if="showNav">
        <img src="@/assets/images/left-arrow.svg" alt="" class="btn-back" @click="goBack" v-if="showBackArrow">
        <img src="@/assets/icon-nav-home.png" class="go-index" alt="" @click="jumpPage('index')" v-else />
        <div class="page-title">{{pageTitle}}</div>
        <div class="right-btn" v-if="routename == 'mycollect'" @click="jumpPage('seerecord')">查看记录</div>
        <div class="right-btn" v-if="routename == 'artist'" @click="jumpPage('artists')">艺术家</div>
      </div>

      <div id="router-wrapper" class="hasTop" :class="{hasBottom:showTabs}">
        <keep-alive exclude="goodsdetail">
          <router-view></router-view>
        </keep-alive>
      </div>

      <!-- 底部tab -->
      <div class="nav-tabs bottom-nav-ignore" v-if="showTabs">
        <div class="tab-item" :class="{active:routename == 'index'}" @click="jumpPage('index')">
          <img src="@/assets/images/tabicon-home-light.png" alt="" v-if="routename == 'index'">
          <img src="@/assets/images/tabicon-home.png" alt="" v-else>
          <div>首发</div>
        </div>
        <div class="tab-item" :class="{active:routename == 'artist'}" @click="jumpPage('artist')">
          <img src="@/assets/images/tabicon-art.png" alt="">
          <div>个人艺术展览馆</div>
        </div>
        <div class="tab-item" :class="{active:routename == 'mine'}" @click="jumpPage('mine')">
          <img src="@/assets/images/tabicon-mine-light.png" alt="" v-if="routename == 'mine'">
          <img src="@/assets/images/tabicon-mine.png" alt="" v-else>
          <div>我的</div>
        </div>
      </div>

      <!-- 建议浏览器打开弹层 -->
      <div class="browser-layer" v-if="isWeiXin">
        <img src="@/assets/icon-browser-arrow.png" alt="">
        <div>
          <p>建议点击右上角...</p>
          <p>选择在浏览器中打开</p>
        </div>
      </div>

    </div>
  </div>
  
</template>

<script>
import { isWeiXin } from '@/utils/tools'

export default {
  name: 'App',
  data(){
    return {
      notShowNavArr:[''],
      hasTabs:['index','artist','mine']
    }
  },
  computed:{
    pageTitle(){
      return this.$route.meta.title
    },
    showNav(){
      let routename=this.$route.name;
      if(this.notShowNavArr.includes(routename)){
        return false;
      }else{
        return true;
      }
    },
    showTabs(){
      let routename=this.$route.name;
      if(this.hasTabs.includes(routename)){
        return true;
      }else{
        return false;
      }
    },
    routename(){
      return this.$route.name;
    },
    showBackArrow(){
      return window.history.length > 1 ? true : false
    },
    isWeiXin(){
      return isWeiXin();
    },
  },
  methods:{
    jumpPage(name){
      this.$router.push({
        name
      });
    },
    goBack(){
      this.$router.go(-1);
    },
  }
}
</script>


<style lang="scss">
.hole-wrapper{
  background:rgba(0,0,0,0.6);
  width:100vw;
  height:100vh;
  position: relative;
}
.ignore{
  width:450px;
  position: fixed;
  left:50%;
  top:0;
  margin-left:-225px;
  height: 100vh;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  };
  box-shadow: 0px 3px 8px 1px rgba(0,0,0,0.6);
}
@mixin flexbetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#app{
  background-color: #0B0B0B;
  min-height: 100vh;
  .titlebar{
    @include flexbetween();
    height:80px;
    line-height: 80px;
    padding:0 24px;
    z-index: 99;
    width:100%;
    position: fixed;
    top:0;
    left:50%;
    box-sizing: border-box;
    background-color: #1F1F1F;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
    &.top-nav-ignore{
      width:450px;
      margin-left: -225px;
    }
    .btn-back{
      width:50px;
      flex-shrink: 0;
    }
    .go-index{
      width:50px;
      flex-shrink: 0;
    }
    .page-title{
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      width:100%;
      text-align: center;
    }
    .right-btn{
      flex-shrink: 0;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255,255,255,0.65);
    }
  }
  #router-wrapper{
    box-sizing: border-box;
    min-height: 100vh;
    background-color: #1F1F1F;
    padding-bottom: 30px;
    &.hasTop{
      padding-top: 80px;
    }
    &.hasBottom{
      padding-bottom: 95px;
    }
  }
  .nav-tabs{
    position: fixed;
    left:50%;
    bottom:0;
    width:100%;
    z-index: 99;
    @include flexbetween();
    height:95px;
    background: #1F1F1F;
    box-shadow: 0px -1px 4px 0px #474A4C;
    &.bottom-nav-ignore{
      width:450px;
      margin-left:-225px;
    }
    .tab-item{
      width:33%;
      text-align: center;
      &>img{
        width:44px;
      }
      &>div{
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #778890;
        line-height: 33px;
      }
      &.active{
        &>div{
          color:#1196DB;
        }
      }
    }
  }
  .browser-layer{
    position: fixed;
    z-index: 100;
    width:100vw;
    height:100vh;
    top:0;
    left:0;
    background:rgba(0,0,0,0.6);
    img{
      position: absolute;
      top:5px;
      right:72px;
      width:216px;
    }
    div{
      position: absolute;
      right:300px;
      top:190px;
      p{
        font-size: 30px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }
}
</style>
