import Vue from 'vue'
import App from './App.vue'
import router from '@/router'

import VueLoadmore from 'vuejs-loadmore';
Vue.use(VueLoadmore);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);

// normalize 初始化
import "normalize.css/normalize.css"

// import "vant/lib/index.css"
import '@/assets/style/common.scss'

import empty from '@/components/empty/empty.js'

import {
  Button,
  Tab, 
  Tabs,
  Tabbar,
  TabbarItem,
  NavBar,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Switch,
  ActionSheet,
  Dialog,
  Popup,
  Area,
  PasswordInput,
  NumberKeyboard,
  Toast,
  Uploader,
  Picker,
  Form,
  Field,
  Radio,
  RadioGroup,
  Checkbox,
  CountDown
} from 'vant'

Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(Switch);
Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Area);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Toast);
Vue.use(Uploader);
Vue.use(Picker);
Vue.use(Form);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CountDown);


Vue.use(empty);

Vue.prototype.imgBaseUrl = 'http://image.nftnet.world/'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
