import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const routes=[
    {
        name:'index',
        path:'/',
        component:()=>import(/* webpackChunkName: "index" */ '@/pages/index'),
        meta:{
            title:'NFTNET'
        }
    },
    {
        name:'login',
        path:'/login',
        component:()=>import(/* webpackChunkName: "login" */ '@/pages/login'),
        meta:{
            title:'登录'
        }
    },
    {
        name:'calendar',
        path:'/calendar',
        component:()=>import(/* webpackChunkName: "calendar" */ '@/pages/calendar'),
        meta:{
            title:'发售日历'
        }
    },
    {
        name:'goodsdetail',
        path:'/goodsdetail/:id/:type',
        component:()=>import(/* webpackChunkName: "goodsdetail" */ '@/pages/goodsdetail'),
        meta:{
            title:'藏品详情'
        }
    },
    {
        name:'artdetail',
        path:'/artdetail',
        component:()=>import(/* webpackChunkName: "artdetail" */ '@/pages/artdetail'),
        meta:{
            title:'藏品详情'
        }
    },
    {
        name:'artistdetail',
        path:'/artistdetail/:id',
        component:()=>import(/* webpackChunkName: "artistdetail" */ '@/pages/artistdetail'),
        meta:{
            title:'艺术家详情'
        }
    },
    {
        name:'pavilion',
        path:'/pavilion/:id',
        component:()=>import(/* webpackChunkName: "pavilion" */ '@/pages/pavilion'),
        meta:{
            title:'藏品馆'
        }
    },
    {
        name:'artist',
        path:'/artist',
        component:()=>import(/* webpackChunkName: "artist" */ '@/pages/artist'),
        meta:{
            title:'NFTNET'
        }
    },
    {
        name:'artists',
        path:'/artists',
        component:()=>import(/* webpackChunkName: "artists" */ '@/pages/artists'),
        meta:{
            title:'艺术家列表'
        }
    },
    {
        name:'buyList',
        path:'/buyList',
        component:()=>import(/* webpackChunkName: "index" */ '@/pages/buyList'),
        meta:{
            title:'我买到的'
        }
    },
    {
        name:'collection',
        path:'/collection',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/collection'),
        meta:{
            title:'我的藏品'
        }
    },
    {
        name:'mycollect',
        path:'/mycollect',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/mycollect'),
        meta:{
            title:'我的藏品'
        }
    },
    {
        name:'collectitem',
        path:'/mycollect/:id',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/mycollectitem'),
        meta:{
            title:'藏品系列'
        }
    },
    {
        name:'recharge',
        path:'/recharge/:chainType/:tokenName',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/recharge'),
        meta:{
            title:'充值'
        }
    },
    {
        name:'social',
        path:'/social',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/social'),
        meta:{
            title:'我的社交'
        }
    },
    {
        name:'airdrop',
        path:'/airdrop',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/airdrop'),
        meta:{
            title:'空投'
        }
    },
    {
        name:'collectdetail',
        path:'/collectdetail/:id',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/collectdetail'),
        meta:{
            title:'藏品详情'
        }
    },
    {
        name:'flaunt',
        path:'/flaunt/:id/:type',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/flaunt'),
        meta:{
            title:'炫耀'
        }
    },
    {
        name:'certificate',
        path:'/certificate/:id/:type',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/certificate'),
        meta:{
            title:'藏品证书'
        }
    },
    // 我的账单 - 提现//充值记录
    {
        name:'bills',
        path:'/bills',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/bills'),
        meta:{
            title:'我的账单'
        }
    },
    // 我的账单 - 藏品买卖
    {
        name:'orderbills',
        path:'/orderbills',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/orderbills'),
        meta:{
            title:'我的账单'
        }
    },
    // nft 购买 提取 记录
    {
        name:'seerecord',
        path:'/seerecord',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/seerecord'),
        meta:{
            title:'查看记录'
        }
    },
    {
        name:'orders',
        path:'/orders',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/orders'),
        meta:{
            title:'我的订单'
        }
    },
    {
        name:'certification',
        path:'/certification',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/certification'),
        meta:{
            title:'实名认证'
        }
    },
    {
        name:'school-works',
        path:'/school/works',
        component:()=>import(/* webpackChunkName: "school" */ '@/pages/school/works'),
        meta:{
            title:'管理作品'
        }
    },
    {
        name:'school-release',
        path:'/school/release',
        component:()=>import(/* webpackChunkName: "school" */ '@/pages/school/release'),
        meta:{
            title:'发布新作品'
        }
    },
    {
        name:'school-applyart',
        path:'/school/applyart',
        component:()=>import(/* webpackChunkName: "school" */ '@/pages/school/applyart'),
        meta:{
            title:'申请成为艺术家'
        }
    },
    {
        name:'school-applypage',
        path:'/school/applypage',
        component:()=>import(/* webpackChunkName: "school" */ '@/pages/school/applypage'),
        meta:{
            title:'中国好创意高校专栏'
        }
    },
    {
        name:'bankcard',
        path:'/bankcard',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/bankcard'),
        meta:{
            title:'绑定银行卡'
        }
    },
    {
        name:'artistapply',
        path:'/artistapply',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/artistapply'),
        meta:{
            title:'申请艺术家'
        }
    },
    {
        name:'contactus',
        path:'/contactus',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/contactus'),
        meta:{
            title:'联系我们'
        }
    },
    {
        name:'withdraw',
        path:'/withdraw',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/withdraw'),
        meta:{
            title:'申请提现'
        }
    },
    {
        name:'rechargenft',
        path:'/rechargenft',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/rechargenft'),
        meta:{
            title:'充值NFT'
        }
    },
    {
        name:'collectionDetail',
        path:'/collectionDetail',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/collectionDetail'),
        meta:{
            title:'藏品详情'
        }
    },
    {
        name:'mine',
        path:'/mine',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/mine'),
        meta:{
            title:'我的'
        }
    },
    {
        name:'interest',
        path:'/interest',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/interest'),
        meta:{
            title:'我的权益'
        }
    },
    {
        name:'transfer-manage',
        path:'/transfer-manage',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/transfer-manage'),
        meta:{
            title:'转赠管理'
        }
    },
    {
        name:'transfer-search',
        path:'/transfer-search',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/transfer-search'),
        meta:{
            title:'转赠'
        }
    },
    {
        name:'transfer',
        path:'/transfer',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/transfer'),
        meta:{
            title:'转赠'
        }
    },
    {
        name:'resetpassword',
        path:'/resetpassword',
        component:()=>import(/* webpackChunkName: "mine" */ '@/pages/mine/resetpassword'),
        meta:{
            title:'重置安全密码'
        }
    },
    {
        name:'codelock',
        path:'/settings/codelock',
        component:()=>import(/* webpackChunkName: "settings" */ '@/pages/mine/codelock'),
        meta:{
            title:'安全密码锁'
        }
    },
    {
        name:'settings',
        path:'/settings',
        component:()=>import(/* webpackChunkName: "settings" */ '@/pages/mine/settings'),
        meta:{
            title:'个人设置'
        }
    },
    {
        name:'changemobile',
        path:'/settings/changemobile',
        component:()=>import(/* webpackChunkName: "settings" */ '@/pages/mine/changemobile'),
        meta:{
            title:'更换手机号'
        }
    },
    {
        name:'changepassword',
        path:'/settings/changepassword',
        component:()=>import(/* webpackChunkName: "settings" */ '@/pages/mine/changepassword'),
        meta:{
            title:'登录密码'
        }
    },
    // {
    //     name:'realname',
    //     path:'/settings/realname',
    //     component:()=>import(/* webpackChunkName: "settings" */ '@/pages/mine/realname'),
    //     meta:{
    //         title:'实名认证'
    //     }
    // },
    {
        name:'addresslist',
        path:'/settings/addresslist',
        component:()=>import(/* webpackChunkName: "settings" */ '@/pages/mine/addresslist'),
        meta:{
            title:'收货地址列表'
        }
    },
    {
        name:'addressdetail',
        path:'/settings/addressdetail/:id?',
        component:()=>import(/* webpackChunkName: "settings" */ '@/pages/mine/addressdetail'),
        meta:{
            title:'收货地址'
        }
    },
    {
        name:'notice',
        path:'/notice',
        component:()=>import(/* webpackChunkName: "settings" */ '@/pages/notice'),
        meta:{
            title:'公告列表'
        }
    },
    {
        name:'noticedetail',
        path:'/noticedetail/:id',
        component:()=>import(/* webpackChunkName: "settings" */ '@/pages/noticedetail'),
        meta:{
            title:'公告详情'
        }
    },
];


export default new Router({
    mode:'history',
    routes,
    // scrollBehavior: () => ({ y: 0 }),
});