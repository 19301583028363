<template>
    <div class="empty-container">
        <div class="empty-txt">-- 暂无数据 --</div>
    </div>    
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.empty-container{
    width:100%;
    height:50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .empty-txt{
        font-size: 36px;
        color:#666;
        text-align: center;
    }
}
</style>